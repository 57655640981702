import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import config from "../../../config";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";

import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";

import ConfirmPlanUpgrade from "./ConfirmPlanUpgrade";
import PayAsYouGoPackages from "./PayAsYouGoPackages";

import { annualToMonthly, formatMonthlyPrice, selectCurrencySymbol } from "./formatters";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.automate.video">
        Automate.video
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Pricing(props) {
  const [country, setCountry] = useState(null);

  const [currency, setCurrency] = useState("EUR");

  //const [pricesIndex, setPricesIndex] = useState(null);
  const [availableCurrencies, setAvailableCurrencies] = useState(["EUR"]);
  const user = useSelector((state) => state.user);

  const [annualToggle, setAnnualToggle] = useState(user?.plan_interval === "month" ? false : true);
  const [debugCountry, setDebugCountry] = useState(null);

  const [plan, setPlan] = useState(null);
  const [planCurrency, setPlanCurrency] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const [coupon, setCoupon] = useState(null);
  let discountPercentage = 0.3;

  const [confirmSubscription, setConfirmSubscription] = useState(null);

  const accessToken = useSelector((state) => state.user.accessToken);

  const { data: dataLocation } = useQuery({
    queryKey: ["userLocation"],
    queryFn: () => axios.get(`https://speed.cloudflare.com/meta`).then((res) => res.data),
  });

  // testing
  //const dataLocation = { country: "IN" };

  let cca2 = country ?? dataLocation?.country ?? "FI";
  console.log(country, dataLocation?.country);
  if (debugCountry) cca2 = debugCountry;
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["getPricelist", cca2],
    queryFn: () =>
      axios
        .post(`${config.socketIo}/pricing/getPricelistForCountry`, { country: cca2, accessToken })
        .then((res) => res.data),
  });

  const countryData = !isLoading || !error ? data?.country : null;
  const plansWithPrices = !isLoading || !error ? data?.plansWithPrices : null;
  console.log({ countryData, plansWithPrices });

  const handlePlanUpgrade = () => {
    window.location.href = confirmSubscription.url;

    setConfirmSubscription(null);
  };

  const handleCancelUpgrade = () => {
    setConfirmSubscription(null);
  };

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV !== "production") setCoupon("yKxejmQs");
    if (process.env.REACT_APP_NODE_ENV === "production") setCoupon("hKAuslBo");
  }, [props.coupon]);

  useEffect(() => {
    if (user && user.id) setUserLoggedIn(true);
    else setUserLoggedIn(false);
    if (user && user.attributes && user?.attributes?.plan_status === "active") {
      if (user.attributes.hasOwnProperty("plan")) setPlan(user.attributes.plan);
      //if (user.attributes.hasOwnProperty("plan_subcriptionid")) setSubscriptionid(user.attributes.plan_subscriptionid);
      if (user?.attributes?.plan_country) setCountry(user.attributes.plan_country);
      if (user?.attributes?.plan_currency) setPlanCurrency(user.attributes.plan_currency);
    }
  }, [user]);

  useEffect(() => {
    setCurrency(countryData?.country?.currencies?.[0]);
    refetch();
  }, [debugCountry]);

  useEffect(() => {
    const availableCurrencies = countryData?.prices?.map((p) => p.currency);
    let foundActivePlanIndex = availableCurrencies?.findIndex((c) => c === planCurrency);
    let priceIndex = foundActivePlanIndex === -1 ? 0 : foundActivePlanIndex;
    console.log({ availableCurrencies, priceIndex });
    if (countryData && countryData.prices && countryData.prices[priceIndex]) {
      setCurrency(countryData.prices[priceIndex].currency);
      //setCurrencySymbol(countryData.prices[0].currencySymbol);
      //setPricesIndex(priceIndex);
      setAvailableCurrencies(countryData?.prices?.map((p) => p.currency));
    }
  }, [countryData, planCurrency]);

  // define font size for pricing line
  let pricingFontSize = " text-4xl";
  let currentTier = null;
  let currentTierAnnualToggle = user?.attributes?.plan_interval === "year";

  if (plansWithPrices?.[currency])
    for (let p of plansWithPrices[currency]) {
      if (annualToggle && p.price_annual / 12 > 999) pricingFontSize = " text-3xl ";
      else if (p.price_monthly > 999) pricingFontSize = " text-3xl ";
      if (p.plan_name === user?.attributes?.plan_name && user?.attributes.plan_status === "active") currentTier = p;
    }

  const getButtonText = (tier) => {
    if (tier.plan_name === "Free") return "Sign up for free";
    if (!userLoggedIn) {
      if (!tier.noTrialPeriod && tier.plan_name === "Basic") return "7 day free trial";
      else return "Subscribe";
    } else {
      if (plan === tier.plan) return "CURRENT PLAN";
      //if (!tier.noTrialPeriod && tier.plan_name === "Basic") return "7 day free trial";
      if (plan?.substring(0, 4) === "free") return "Subscribe";
      else return "CHANGE TO THIS PLAN";
    }
  };

  let queryString = null;
  if (props.location.search.substr(1) && props.location.search.substr(1).length)
    queryString = props.location.search.substr(1);

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  return (
    <React.Fragment>
      <Helmet>
        <title>Automate.video | Pricing </title>
      </Helmet>

      <CssBaseline />
      <ConfirmPlanUpgrade
        open={confirmSubscription}
        currentPlan={user?.attributes?.plan_name}
        currentTier={currentTier}
        currentTierAnnualToggle={currentTierAnnualToggle}
        tier={confirmSubscription?.tier}
        annualToggle={confirmSubscription?.annualToggle}
        handleConfirm={handlePlanUpgrade}
        handleCancel={handleCancelUpgrade}
      />

      {user?.attributes?.plan_status !== "active" && (
        <div className="flex flex-row justify-end px-8 pt-8">
          <div className="relative group">
            <button className="py-2.5 px-3 w-full md:text-sm text-site bg-transparent border border-dimmed  focus:border-brand focus:outline-none focus:ring-0 peer flex items-center justify-between rounded font-semibold">
              {currency}
            </button>
            <div className="absolute z-[99] top-[100%] left-[50%] translate-x-[-50%] rounded-md overflow-hidden shadow-lg min-w-[200px] w-max peer-focus:visible peer-focus:opacity-100 opacity-0 invisible duration-200 p-1 bg-gray-100 dark:bg-gray-800  border border-dimmed text-xs md:text-sm">
              {availableCurrencies?.map((c, idx) => (
                <div
                  onClick={() => {
                    //setPricesIndex(idx);
                    setCurrency(c);
                  }}
                  key={c}
                  className=" w-full block cursor-pointer hover:bg-white dark:hover:bg-gray-900 dark:bg-gray-800 hover:text-link px-3 py-2 rounded-md"
                >
                  {c}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <Container maxWidth="sm" component="main">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          gutterBottom
          style={{
            color: "#36355b",

            fontSize: "3em",
          }}
        >
          {props.campaign === "secret" ? "Secret Plans" : "Pricing"}
        </Typography>
        {props.campaign === "secret" || props.campaign === "virtualpresenterplan" ? null : (
          <Typography variant="h5" align="center" color="textSecondary" component="p">
            <span
              onClick={() => {
                window.location.href = config.player + "/login?mode=register";
                //props.history.push("/login?mode=register");
              }}
              style={{ fontWeight: 600, color: "rgb(78, 78, 112)", cursor: "pointer" }}
            >
              Sign up for free.
            </span>{" "}
            No credit card required.
          </Typography>
        )}

        <div className="w-full h-full flex flex-col justify-center items-center mt-4">
          <div className="flex justify-center items-center">
            <label htmlFor="Toggle2" className="inline-flex items-center space-x-4 cursor-pointer dark:text-gray-100">
              <span
                onClick={() => setAnnualToggle(false)}
                className={
                  "text-lg rounded-xl py-2 px-4" +
                  (!annualToggle ? " font-bold text-black-400 bg-blue-header" : " bg-gray-300")
                }
              >
                Monthly
              </span>

              <span
                onClick={() => setAnnualToggle(true)}
                className={
                  "text-lg rounded-xl py-2 px-4" +
                  (annualToggle ? " font-bold text-black-400 bg-blue-header" : " bg-gray-300")
                }
              >
                💥 Yearly: get 6 months free
              </span>
            </label>
          </div>
        </div>

        {props.campaign === "virtualpresenterplan" ? null : (
          <Typography
            variant="h5"
            align="center"
            style={{ fontWeight: 600, color: "rgb(78, 78, 112)", marginTop: "0.5em" }}
            component="p"
          >
            {countryData?.noTrialPeriod || (userLoggedIn && plan?.substring(0, 4) !== "free")
              ? ""
              : "7 day free trial on Basic plan"}
          </Typography>
        )}
      </Container>
      {/* End hero unit */}
      {process.env.REACT_APP_NODE_ENV !== "production" ? (
        <div className="flex flex-row w-full justify-start px-24">
          <label htmlFor="debug-country">DEBUG: Choose country:</label>
          <select
            value={debugCountry}
            name="debug-country"
            id="debug-country"
            onChange={(e) => setDebugCountry(e.target.value)}
          >
            <option value="">Choose country</option>
            {data?.countrylist.map((c) => (
              <option key={c.name} value={c.cca2}>
                {c.name}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      <Container maxWidth={false} component="main">
        <div className="flex flex-row flex-wrap justify-center p-4 items-stretch rounded-lg">
          {plansWithPrices[currency]?.map((tier) => {
            if (tier.plan_name === "Free" && props.campaign === "secret") return null;
            if (tier.plan_name === "Free" && userLoggedIn) return null;
            if (tier.plan_name === "Free" && props.campaign === "virtualpresenterplan") return null;
            if (tier.plan_name === "Basic" && props.campaign === "virtualpresenterplan") return null;
            else
              return (
                <div
                  className="flex flex-row max-w-2xl md:max-w-md lg:max-w-sm xl:max-w-19 2xl:max-w-19 justify-center p-0"
                  item
                  key={tier.plan_name}
                  container
                >
                  <Grid
                    item
                    className="flex flex-col justify-between w-80 m-3 rounded-lg p-0 m-3"
                    component={Card}
                    key={tier.plan_name}
                    style={{
                      margin: 12,
                      // Use flex layout with column direction for components in the card
                      // (CardContent and CardActions)

                      padding: "0px",
                    }}
                  >
                    <div item className="flex flex-col rounded-lg justify-start">
                      <CardHeader
                        title={tier.plan_name}
                        subheader={tier.subheader}
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        action={tier.plan_name === "Professional" ? <StarIcon /> : null}
                        className="bg-blue-header "
                        style={{ height: 80 }}
                      />

                      <div className="flex flex-row mt-2 mb-2 justify-center align-baseline">
                        <ul style={{ paddingBottom: "0em" }}>
                          {props.campaign === "secret" ? (
                            <React.Fragment>
                              <Typography
                                component="li"
                                variant="h5"
                                color="textPrimary"
                                style={{
                                  color: "gray",
                                  fontSize: "1.8em",
                                  textDecoration: "line-through",
                                  textDecorationColor: "red",
                                  textDecorationStyle: "edges",
                                  textAlign: "center",
                                }}
                              >
                                <span className="text-gray-500 text-2xl">
                                  {" "}
                                  {tier.currencySymbol} {tier.price_monthly}/mo
                                </span>
                              </Typography>{" "}
                              <Typography component="li" variant="h3" color="textPrimary" style={{ fontSize: "2.8em" }}>
                                {tier.currencySymbol}{" "}
                                {tier.zero_decimal
                                  ? Math.round(tier.price_monthly * (1 - discountPercentage))
                                  : Math.round(tier.price_monthly * (1 - discountPercentage) * 100) / 100}
                                <span className="text-gray-500 text-xl"> /mo</span>
                              </Typography>
                            </React.Fragment>
                          ) : (
                            <div className={"text-black-400 opacity-90 " + pricingFontSize}>
                              {tier.currencySymbol}{" "}
                              {annualToggle
                                ? formatMonthlyPrice(annualToMonthly(tier.price_annual))
                                : formatMonthlyPrice(tier.price_monthly)}
                              <span className="text-gray-500 text-xl">{" /mo"}</span>
                            </div>
                          )}
                        </ul>
                      </div>
                      <ul className="m-2" style={{ paddingBottom: "1em" }}>
                        {tier.plan_name !== "Free" && annualToggle && (
                          <Typography component="li" variant="caption" align="center">
                            6 months free, billed yearly {tier.currencySymbol} {tier.price_annual}.
                          </Typography>
                        )}
                        {tier.plan_name !== "Free" && !annualToggle && (
                          <Typography
                            className="cursor-pointer"
                            component="li"
                            variant="caption"
                            align="center"
                            onClick={() => setAnnualToggle(true)}
                          >
                            Get 6 months free with yearly billing.
                          </Typography>
                        )}
                        {tier.plan_name == "Free" && (
                          <Typography component="li" variant="caption" align="center" className="text-white">
                            free
                          </Typography>
                        )}
                      </ul>
                      <ul style={{ paddingBottom: "1em" }}>
                        {tier.quota.map((line) => (
                          <Typography
                            style={{ fontWeight: 600 }}
                            component="li"
                            variant="subtitle1"
                            align="center"
                            key={line}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>

                      <ul className={"pb-5 bg-blue-header rounded-lg border-orange border"}>
                        {tier.highlightBox.map((line) => (
                          <li
                            key={line.t}
                            className={
                              " text-center " +
                              (line.orangeBold ? " bg-orange  rounded-t-lg" : "") +
                              (line.divider ? " border-slate-400 border-b " : "")
                            }
                          >
                            <div className="mx-2">
                              <span
                                className={
                                  "text-sm break-normal text-[1rem] leading-[28px] w-full" +
                                  (line.bold ? " font-semibold text-[#4e4e70] " : "") +
                                  (line.orangeBold
                                    ? " font-semibold text-white p-2 rounded-lg w-auto inline-block "
                                    : "")
                                }
                              >
                                {line.t}
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>

                      <div className="underline font-bold text-base mt-4 text-center">{tier.descriptionTitle}</div>

                      <ul style={{ paddingTop: "1em" }}>
                        {tier.description.map((line) => (
                          <Typography
                            className="text-sm break-normal"
                            variant="subtitle1"
                            component="li"
                            align="center"
                            key={line.t}
                          >
                            {line.bold ? (
                              <span style={{ fontWeight: 600, color: "rgb(78, 78, 112)" }}>{line.t}</span>
                            ) : (
                              line.t
                            )}
                          </Typography>
                        ))}
                      </ul>
                      <ul style={{ paddingTop: "1em" }}>
                        {tier.notes.map((line) => (
                          <Typography component="li" variant="caption" align="center" key={line}>
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </div>
                    <CardActions style={{ padding: 12 }}>
                      <button
                        className={
                          "text-white rounded-lg w-full p-3 text-base " +
                          (plan === tier.plan ? "bg-gray-400" : "bg-blue-dark")
                        }
                        disabled={plan === tier.plan ? true : false}
                        fullWidth
                        variant={tier.buttonVariant}
                        color="primary"
                        onClick={() => {
                          if (tier.plan_name === "Free") {
                            //props.history.push("/login?mode=register");
                            window.location.href =
                              config.player + "/login?mode=register" + (queryString ? "&" + queryString : "");
                          } else if (user?.attributes?.plan_subscriptionid) {
                            setConfirmSubscription({
                              url:
                                config.player +
                                "/checkout/subscription?hash=" +
                                tier.hash +
                                (annualToggle ? "&interval=year" : "") +
                                (queryString ? "&" + queryString : ""),
                              tier,
                              annualToggle,
                            });
                            /* window.location.href =
                              config.player +
                              "/checkout/subscription?hash=" +
                              tier.hash +
                              (annualToggle ? "&interval=year" : "") +
                              (queryString ? "&" + queryString : ""); */
                          } else {
                            // if (props.campaign === "secret") dispatch(Actions.addToCart([{ ...tier, coupon: coupon }]));
                            // else dispatch(Actions.addToCart([{ ...tier }]));
                            window.location.href =
                              config.player +
                              "/checkout/subscription?hash=" +
                              tier.hash +
                              (annualToggle ? "&interval=year" : "") +
                              (queryString ? "&" + queryString : "");
                          }
                        }}
                      >
                        {getButtonText(tier)}
                      </button>
                    </CardActions>
                  </Grid>
                </div>
              );
          })}
        </div>
      </Container>
      {/* Footer */}
      {userLoggedIn ? "" /*<ShowCreditsButton userLoggedIn={userLoggedIn} />*/ : ""}
      <PayAsYouGoPackages currency={currency} />
      <Box mt={5}>
        <Copyright />
      </Box>
      {/* End footer */}
    </React.Fragment>
  );
}
